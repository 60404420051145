
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
  getCurrentInstance,
} from "vue";
import arraySort from "array-sort";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

interface IHeaderConfiguration {
  name?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
}

export default defineComponent({
  name: "KtDatatable",
  props: {
    tableHeader: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    tableData: {type: Array, required: true},
    emptyTableText: {type: String, default: "No data found"},
    loading: {type: Boolean, default: false},
    currentPage: {type: Number, default: 1},
    enableItemsPerPageDropdown: {type: Boolean, default: true},
    total: {type: Number, default: 0},
    rowsPerPage: {type: Number, default: 10},
    order: {type: String, default: "asc"},
    sortLabel: {type: String, default: ""},
  },
  emits: ["current-change", "sort", "items-per-page-change"],

  setup(props, {emit}) {
    const data = ref(props.tableData);
    const currentSort = ref<string>("");
    const order = ref(props.order);
    const label = ref(props.sortLabel);
    const pagination = ref<IPagination>({
      page: 1,
      total: props.total,
      rowsPerPage: props.rowsPerPage,
    });

    const vnodeProps = getCurrentInstance()?.vnode.props || {};

    watch(data.value, () => {
      console.log("data Change")
      if ("onCurrentChange" in vnodeProps) {
        currentSort.value = label.value + order.value;
      } else {
        pagination.value.total = data.value.length;
      }
    });

    onMounted(() => {
      currentSort.value = label.value + order.value;
      pagination.value.total = props.total ? props.total : data.value.length;
      pagination.value.rowsPerPage = props.rowsPerPage;
    });

    const getItems = computed(() => {
      console.log("data Change comuted")
      if ("onCurrentChange" in vnodeProps) {
        return data.value;
      } else {
        const clone = JSON.parse(JSON.stringify(data.value));
        const startFrom =
          pagination.value.page * pagination.value.rowsPerPage -
          pagination.value.rowsPerPage;
        return clone.splice(startFrom, pagination.value.rowsPerPage);
      }
    });

    const currentPageChange = (val) => {
      if ("onCurrentChange" in vnodeProps) {
        emit("current-change", val);
      } else {
        pagination.value.page = val;
      }
    };

    const sort = (columnName, sortable) => {
      if (sortable === false) {
        return;
      }

      if ("onSort" in vnodeProps) {
        if (order.value === "asc") {
          order.value = "desc";
          emit("sort", {columnName: columnName, order: "desc"});
        } else {
          order.value = "asc";
          emit("sort", {columnName: columnName, order: "asc"});
        }
      } else {
        if (order.value === "asc") {
          order.value = "desc";
          arraySort(data.value, columnName, {reverse: false});
        } else {
          order.value = "asc";
          arraySort(data.value, columnName, {reverse: true});
        }
      }
      currentSort.value = columnName + order.value;
    };

    const setItemsPerPage = (event) => {
      if ("onItemsPerPageChange" in vnodeProps) {
        emit("items-per-page-change", parseInt(event.target.value));
      } else {
        pagination.value.rowsPerPage = parseInt(event.target.value);
      }
    };

    return {
      data,
      pagination,
      currentPageChange,
      getItems,
      sort,
      currentSort,
      setItemsPerPage,
    };
  },
  methods: {
    setData(data) {

      this.data = data;
      this.pagination.rowsPerPage = this.pagination.rowsPerPage ? this.pagination.rowsPerPage : 10;
      this.pagination.total = data.length;
      this.$forceUpdate();
    },
  }

});
