
import {defineComponent, ref} from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import {IEmployee} from "@/model/employee";
import ApiService from "@/core/services/ApiService";
import dateformat from "@/core/filters/filters";
import {Api} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2";


export default defineComponent({
  name: "EmployeeOverviewTable",
  components: {
    Datatable,
  },
  props: {
    dataApi: {type: String, required: true},
    paramsApi: {type: Object, default: ref({})},
  },
  setup() {
    const checkedEmployees = ref([]);
    const tableHeader = ref([
      {
        key: "checkbox",
      },
      {
        key: "state",
      },
      {
        name: "#ID",
        key: "InternalId",
        sortable: true,
      },
      {
        name: "Příjmení",
        key: "Surname",
        sortable: true,
      },
      {
        name: "Jméno",
        key: "Name",
        sortable: true,
      },
      {
        name: "ID Střediska",
        key: "FactoryNumber",
        sortable: true,
      },
      {
        name: "Středisko",
        key: "CompanyName",
        sortable: true,
      },
      {
        name: "Datum nástupu",
        key: "WorkStartDate",
      },
      {
        name: "Akce",
        key: "actions",
        sortable: false,
      },
    ]);
    const tableData = ref<Array<IEmployee>>([]);
    const initEmployees = ref<Array<IEmployee>>([]);
    const loading = ref(true);

    const search = ref<string>("");
    const searchItems = () => {
      console.log(tableData.value.length);
      tableData.value.splice(0, tableData.value.length, ...initEmployees.value);
      if (search.value !== "") {
        console.log("ano");
        let results: Array<IEmployee> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          console.log(tableData.value[j]);
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object") && !(typeof obj[key] === "boolean")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };
    return {
      checkedEmployees,
      tableHeader,
      tableData,
      initEmployees,
      searchItems,
      search,
      loading,
      dateformat,
    }
  },
  data() {
    return {
      employeesCount: 0,
    }
  },
  mounted() {
    this.initData()
  },

  methods: {
    initData() {
      ApiService.get(this.$props.dataApi, this.$props.paramsApi).then(({data}) => {
        this.tableData = data;
        this.initEmployees.splice(0, this.tableData.length, ...this.tableData);
        this.loading = false;
        (this.$refs['employee_table'] as any).setData(data);
      }).catch(({response}) => {
        console.error(response);
      });
    },
    FireSelectedEmployers() {
      let checkedFactory: Array<IEmployee> = this.checkedEmployees;
      checkedFactory.forEach((item) => {
        ApiService.get(Api.FIRE_EMPLOYEE, {'employee_id': item});
        console.log('Fire ' + item);
      });
      checkedFactory.length = 0;
      this.initData();
    },
  }


});
